import DANA_BANK1_PNG from '@/assets/images/dana/bank1.png';
import DANA_BANK2_PNG from '@/assets/images/dana/bank2.png';
import DANA_BANK3_PNG from '@/assets/images/dana/bank3.png';
import DANA_BANK4_PNG from '@/assets/images/dana/bank4.png';
import DANA_BANK5_PNG from '@/assets/images/dana/bank5.png';
import DANA_BANK6_PNG from '@/assets/images/dana/bank6.png';
import DANA_BANK7_PNG from '@/assets/images/dana/bank7.png';

export const DANA_BANK_OPTIONS = [
  { code: 'VIRTUAL_ACCOUNT_BCA', image: DANA_BANK1_PNG },
  { code: 'VIRTUAL_ACCOUNT_BNI', image: DANA_BANK2_PNG },
  { code: 'VIRTUAL_ACCOUNT_BRI', image: DANA_BANK3_PNG },
  { code: 'VIRTUAL_ACCOUNT_BTPN', image: DANA_BANK4_PNG },
  { code: 'VIRTUAL_ACCOUNT_CIMB', image: DANA_BANK5_PNG },
  { code: 'VIRTUAL_ACCOUNT_MANDIRI', image: DANA_BANK6_PNG },
  { code: 'VIRTUAL_ACCOUNT_PERMATA', image: DANA_BANK7_PNG },
];
